body {
	color: #000
}

.podcastBanner {
	background: linear-gradient(to right,#dc6891 0%,#7a5ac3 100%) !important;
	color: #fff;
	font-size: 16px;
	font-weight: bold;
}

.podcastLink {
	color: rgb(250, 212, 87);
}

