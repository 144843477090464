.App {
  text-align: center;
  color: #000;
}

.everythingGrowsImg {
  max-width: 490px;
}

.cardTitle {
  font-size: 50px;
  font-weight: bold;
  margin-bottom: 25px;
  text-align: left;
  /* color: #323643; */
  color: #495057;
}

.socialIcon {
  color: #495057;
  text-decoration: none;
  margin-right: 25px;
}

.socialIcon:hover,
.socialIconFooter:hover {
  color: #00ccff;
}

.image-gallery-slides {
  height: 100%; 
}

.instaComment {
  transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
}

.image-gallery-image {
  width: 30.5rem !important;
  height: 60vh !important;
  /* border: 1px solid; */
  /* border-radius: 5px; */
  background: rgb(255, 255, 255);
  border: 0px;
  border-radius: 3px;
  box-shadow: rgb(0 0 0 / 50%) 0px 0px 1px 0px, rgb(0 0 0 / 15%) 0px 1px 10px 0px;
  margin: 1px;
  max-width: 540px;
  min-width: 326px;
  padding: 16px;
  width: calc(100% - 2px);
}

.image-gallery-svg {
  display: none;
}

a.nav-link:hover {
  color: #00ccff !important;
}

a.nav-link.active {
  color: #00ccff !important;
}

.socialIconFooter {
  font-size: 17px;
  color: rgb(134, 142, 150);
  text-decoration: none;
  margin-right: 25px;
}

.podcastImage {
  max-height: 500px;
  overflow: hidden;
}

.cardHeader {
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 25px;
  text-align: left;
  /* color: #323643; */
  color: #00ccff;
}

.cardHeader2 {
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 25px;
  text-align: left;
  /* color: #323643; */
  color: #495057;
}

.cardText {
  margin-bottom: 35px;
  font-size: 22px;
  text-align: left;
  /* color: #747a87; */
  color: #495057;
}

.cardIcon {
  /* margin-bottom: 35px; */
  /* font-size: 22px;  */
  /* color: #747a87; */
  color: #495057;
}

.text-left {
  text-align: left;
}

body, .card {
  background: rgb(248 249 250) !important;
}

.text-right {
  text-align: right;
}


.row {
  /* display: flex; */
  /* flex-wrap: wrap; */
  /* padding: 0 4px; */
}

/* Create four equal columns that sits next to each other */
/* #egGalleryCols .col {
  flex: 25%;
  max-width: 25%;
  padding: 0 4px;
} */

.col img {
  margin-top: 8px;
  vertical-align: middle;
  width: 100%;
}

footer {
  background-color: rgb(52, 58, 64);
  color: rgb(255, 255, 255);
}

#copyRightText {
  text-align: left;
}

#copyRightIcons {
  text-align: right;
  padding-right: 3rem!important;
}

/* Responsive layout - makes a two column-layout instead of four columns */
@media screen and (max-width: 800px) {
  .col {
    flex: 50%;
    max-width: 50%;
  }
}

@media screen and (max-width: 410px) {
  .jumbotronText {
    display: none;
  }

  p.cardTitle {
    font-size: 35px !important;
  }
}

@media screen and (max-width: 767px) {

  #copyRightIcons,
  #copyRightText {
    text-align: center;  
  }

  #copyRightIcons {
    padding-right: calc(var(--bs-gutter-x) * .5) !important;
  }
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {

  .col {
    flex: 100%;
    max-width: 100%;
  }
}