.card-img {
  opacity: .5;
}

.card {
  border: none !important;
  border-radius: 0 !important;
}

.jumbotronTitle {
  font-size: 100px;
  font-weight: bold;
  margin-top: 15%;
}

.jumbotronText {
  font-size: 19px;
}

/* Responsive layout - makes a two column-layout instead of four columns */
@media screen and (max-width: 800px) {
  .jumbotronTitle {
    font-size: 75px;
    font-weight: bold;
    margin-top: 15%;
  }
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .jumbotronTitle {
    font-size: 50px;
    font-weight: bold;
    margin-top: 15%;
  }
}